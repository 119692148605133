<route>
{
  "meta": {
    "auth": "sysUserManage"
  }
}
</route>

<template>
  <div class="pageWithTable">
    <bm-cardTabList :list="tabList" current="权限管理"></bm-cardTabList>
    <el-card class="tableContainer">
      <div class="header" slot="header">
        <span class="title">权限列表</span>

        <el-button size="mini" type="success" @click="handleAdd(0)" style="float: right; ">添加权限</el-button>
        <el-button size="mini" type="success" @click="handleExpand" style="float: right;margin-right: 10px "
          >一键展开或收起</el-button
        >
        <el-button size="mini" type="success" @click="handlerDragger" style="float: right;margin-right: 10px"
          >拖动修改权限</el-button
        >
      </div>
      <el-table
        :data="list"
        ref="table"
        row-key="id"
        lazy
        fit
        :default-expand-all="isExpand"
        v-loading="loading"
        :load="loadTableData"
        :row-class-name="tableRowClassName"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column align="center" width="240px" prop="id" label="id"> </el-table-column>
        <el-table-column align="center" prop="privilegeName" label="名称"> </el-table-column>
        <el-table-column align="center" prop="privilegeId" label="Code"> </el-table-column>
        <el-table-column align="center" prop="urlMethod" label="Method"> </el-table-column>
        <el-table-column align="center" prop="menuUrl" label="URL"> </el-table-column>
        <!-- <el-table-column align="center" prop="parentId" label="父级"> </el-table-column> -->
        <el-table-column align="center" prop="deleteStatus" label="状态">
          <template slot-scope="scope">
            <el-switch
              @change="onStatusChange(scope.row)"
              :value="scope.row.deleteStatus"
              :active-value="0"
              :inactive-value="1"
            ></el-switch>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="deleteStatus" label="是否忽略地区限制">
          <template slot-scope="scope">
            {{ scope.row.areaStatus === 1 ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleAdd(scope.row.id)">添加子级</el-button>
            <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog :visible.sync="dragger.show" :close-on-click-modal="false" title="拖动修改权限">
      <el-form label-width="100px" class="draggerDialog">
        <el-form-item label="选择权限">
          <div class="permissionList">
            <el-tree
              @node-drop="handleDrop"
              :props="{
                label: 'privilegeName',
                children: 'children'
              }"
              draggable
              default-expand-all
              :data="permissionList"
              node-key="id"
              ref="permissionTree"
              show-checkbox
              highlight-current
            >
            </el-tree>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" :visible.sync="isShowEditDialog" :title="form.id ? '编辑' : '新增'">
      <el-form label-width="160px" :model="form" :rules="rules" ref="form">
        <el-form-item v-if="form.id" label="ID">
          <el-input v-model.trim="form.id" disabled></el-input>
        </el-form-item>
        <el-form-item v-if="form.parentId" label="父级">
          <el-input v-model.trim="form.parentId" disabled></el-input>
        </el-form-item>
        <el-form-item label="权限标示">
          <el-input v-model.trim="form.privilegeId" :disabled="!!form.id && !!form.isEmptyCode"></el-input>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model.trim="form.privilegeName"></el-input>
        </el-form-item>
        <el-form-item label="请求方法">
          <el-radio-group v-model="form.urlMethod">
            <el-radio-button label="get"></el-radio-button>
            <el-radio-button label="post"></el-radio-button>
            <el-radio-button label="put"></el-radio-button>
            <el-radio-button label="delete"></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="请求地址">
          <el-input v-model.trim="form.menuUrl"></el-input>
        </el-form-item>

        <el-form-item label="是否忽略地区限制">
          <el-switch v-model="form.areaStatus" :active-value="1" :inactive-value="0"></el-switch>
          {{ form.areaStatus === 1 ? '是' : '否' }}
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button round @click="isShowEditDialog = false">取消</el-button>
        <el-button round type="primary" @click="handleSubmitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
import tabList from '../tabList'
import { mapGetters, mapActions } from 'vuex'

export default {
  mixins: [pagination],
  data: () => ({
    tabList,
    list: [],
    rules: {},
    filter: {
      id: ''
    },
    permissionList: [],
    dragger: {
      show: false
    },
    isExpand: false,
    isShowEditDialog: false,
    form: {
      id: '',
      privilegeId: '',
      privilegeName: '',
      urlMethod: '',
      menuUrl: '',
      parentId: '',
      areaStatus: 1,
      isEmptyCode: false
    }
  }),
  computed: {
    ...mapGetters({})
  },
  watch: {},
  created() {
    if (sessionStorage.getItem('isExpand')) {
      this.isExpand = JSON.parse(sessionStorage.getItem('isExpand'))
    }
  },
  methods: {
    handleDrop(draggingNode, dropNode, dropType, ev) {
      console.log('完成后id' + dropNode.data.id)

      console.log('完成后P' + dropNode.data.parentId)
      console.log('开始前id' + draggingNode.data.id)
      console.log('开始后P' + draggingNode.data.parentId)

      let parentId
      if (dropNode.data.parentId === draggingNode.data.parentId) {
        parentId = dropNode.data.id
      } else {
        parentId = dropNode.data.parentId
      }
      // return
      this.$http
        .put('boom-center-admin-service/sysAdmin/sysPrivilege/updateParentId', {
          id: draggingNode.data.id,
          parentId: parentId
        })
        .then(res => {
          this.getList()
          this.$message.success('拖动成功')
        })
        .catch(err => {
          this.getList()
          this.$message.error(err.msg)
        })
    },
    handlerDragger() {
      this.dragger.show = true
    },
    handleExpand() {
      sessionStorage.setItem('isExpand', JSON.stringify(!this.isExpand))

      window.location.reload()
    },
    onStatusChange(val) {
      this.loading = true
      return this.$http
        .put(`/boom-center-admin-service/sysAdmin/sysPrivilege/updateDeleteStatus`, {
          id: val.id,
          deleteStatus: val.deleteStatus ? 0 : 1
        })
        .then(res => {
          this.list = []
          this.getList()
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false
          }, 500)
        })
    },

    loadTableData(tree, treeNode, resolve) {
      let level
      if (tree.level === 1) {
        level = 2
      } else if (tree.level == 2) {
        level = 3
      } else if (tree.level == 3) {
        level = 4
      } else if (tree.level == 4) {
        level = 5
      } else if (tree.level == 5) {
        level = 6
      }
      console.log(level)
      this.$http
        .get('/boom-center-admin-service/sysAdmin/sysPrivilege', {
          params: {
            parentId: tree.id,
            size: 100
          }
        })
        .then(res => {
          resolve(res.list.map(i => ({ ...i, hasChildren: true, level: level })))
        })
    },
    handleDelete(val, deleteStatus = 0) {
      return this.$http
        .put(`/boom-center-admin-service/sysAdmin/sysPrivilege/updateDeleteStatus`, {
          id: val.id,
          deleteStatus
        })
        .then(res => {
          this.getList()
        })
    },
    handleAdd(parentId = '') {
      this.form = {
        id: '',
        privilegeId: '',
        privilegeName: '',
        urlMethod: 'get',
        menuUrl: '/sysAdmin/',
        parentId: '',
        isEmptyCode: false,
        areaStatus: 1,
        parentId
      }
      this.isShowEditDialog = true
    },
    handleEdit(val) {
      this.form = { ...val, isEmptyCode: !!val.privilegeId }
      this.isShowEditDialog = true
    },
    handleSubmitForm() {
      const loading = this.$loading({
        lock: true,
        text: '正在提交，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      Promise.resolve()
        .then(() => {
          if (this.form.id) {
            return this.$http.put(`/boom-center-admin-service/sysAdmin/sysPrivilege`, this.form)
          } else {
            return this.$http.post('/boom-center-admin-service/sysAdmin/sysPrivilege', this.form)
          }
        })
        .then(() => {
          this.$message.success('操作成功')
          this.isShowEditDialog = false
          this.getList()
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          loading.close()
        })
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.level === 1) {
        return 'row-1'
      } else if (row.level === 2) {
        return `row-2`
      } else if (row.level === 3) {
        return `row-3`
      } else if (row.level === 4) {
        return `row-4`
      } else if (row.level === 5) {
        return `row-5`
      } else {
        return ``
      }
    },
    addLevel(val, level) {
      val.map(item => {
        if (item.parentId == 0) {
          level = 1
        }
        item.level = level
        if (item.children && item.children.length > 0) {
          item.children = this.addLevel(item.children, level + 1)
        }
      })
      return val
    },
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1
      this.loading = true
      this.$http
        .get('/boom-center-admin-service/sysAdmin/sysPrivilege/selectNodeList')
        .then(res => {
          this.list = this.addLevel(res)
          this.permissionList = res
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.componentContainer {
  width: 100%;
}
/deep/.el-table .row-1 {
  background: oldlace;
}

/deep/.el-table .row-2 {
  background: #f0f9eb;
}
/deep/.el-table .row-3 {
  background: #f5f5f5;
}
/deep/.el-table .row-4 {
  background: #e8f1f1;
}
/deep/.el-table .row-5 {
  background: rgb(255, 249, 250);
}
.draggerDialog {
  overflow: hidden;
  overflow-y: scroll !important;
  height: 680px;
}
</style>
